












































@import '@design';

.numbers {
    @include formbox;

    display: flex;
    width: 100%;
    height: 47px;
    overflow: hidden;
    &:not(:last-child) {
        margin-right: 5px;
    }
    div {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        height: 100%;
        &:not(:last-child) {
            border-right: 1px solid #f5f6fa;
        }
    }
}
.hasFocus {
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px $color-link;
}
